import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import CandidatesInfoPage from './CandidatesInfoPage';
import LoginPage from './LoginPage'; // Import the LoginPage component
import './App.css'; // Import your CSS file

function App() {
  const [emailId, setEmailId] = useState('');
  const [password, setPassword] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [companyId, setCompanyId] = useState('');

  // Set the page title
  useEffect(() => {
    document.title = "Recruiter Feedback Signup";
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      email: emailId,
      password: password,
      recruitee_api_key: apiKey,
      recruitee_company_id: companyId,
    };

    try {
      const response = await fetch('https://qa.api.94talent.com/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        alert('Signup successful!');
        localStorage.setItem('token', data.token); // Store the token
        window.location.href = '/candidatesinfo'; // Use window.location to navigate
      } else {
        alert(`Signup failed: ${data.message}`);
      }
    } catch (error) {
      console.error('Error during signup:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route
            path="/"
            element={
              <div className="form-container">
                <h2 className="form-title">Signup Page</h2>
                <form onSubmit={handleSubmit} className="form">
                  <div className="form-group">
                    <label>Email ID:</label>
                    <input
                      type="email"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                      required
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Password:</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Recruitee API Key:</label>
                    <input
                      type="text"
                      value={apiKey}
                      onChange={(e) => setApiKey(e.target.value)}
                      required
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label>Recruitee Company ID:</label>
                    <input
                      type="text"
                      value={companyId}
                      onChange={(e) => setCompanyId(e.target.value)}
                      required
                      className="form-input"
                    />
                  </div>
                  <button type="submit" className="form-button">Sign Up</button>
                </form>
                <Link to="/login" className="link-button">Go to Login Page</Link>
              </div>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/candidatesinfo" element={<CandidatesInfoPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
