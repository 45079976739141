import React, { useEffect, useState } from 'react';
import './CandidatesInfoPage.css'; // Import your CSS file

function CandidatesInfoPage() {
    const [candidates, setCandidates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const candidatesPerPage = 10; // Adjust the number of candidates per page as needed

    useEffect(() => {
        const fetchCandidates = async () => {
            const token = localStorage.getItem('token'); // Retrieve the token

            if (!token) {
                alert('No token found. Please log in again.');
                return;
            }

            try {
                const response = await fetch('https://qa.api.94talent.com/fetch_candidates', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setCandidates(data.candidates); // Adjust based on your backend response structure
                } else {
                    alert(`Failed to fetch candidates: ${data.message}`);
                }
            } catch (error) {
                console.error('Error fetching candidates:', error);
                alert('An error occurred while fetching candidates.');
            } finally {
                setLoading(false);
            }
        };

        fetchCandidates();
    }, []);

    // Calculate the indices for the current page
    const indexOfLastCandidate = currentPage * candidatesPerPage;
    const indexOfFirstCandidate = indexOfLastCandidate - candidatesPerPage;
    const currentCandidates = candidates.slice(indexOfFirstCandidate, indexOfLastCandidate);

    // Calculate the total number of pages
    const totalPages = Math.ceil(candidates.length / candidatesPerPage);

    // Handler to go to the next page
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handler to go to the previous page
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="candidates-container">
            <h2 className="candidates-title">Candidates Information</h2>
            {candidates.length > 0 ? (
                <>
                    <table className="candidates-table">
                        <thead>
                            <tr>
                                <th>Candidate ID</th>
                                <th>Email</th>
                                <th>Feedback Approved</th>
                                <th>Feedback Summary</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCandidates.map((candidate, index) => (
                                <tr key={index} className="candidate-row">
                                    <td>{candidate.candidate_id || 'N/A'}</td>
                                    <td>{candidate.email || 'N/A'}</td>
                                    <td>{candidate.is_feedback_approved ? 'Yes' : 'No'}</td>
                                    <td>{candidate.feedback_summary || 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={prevPage} disabled={currentPage === 1} className="pagination-button">
                            Previous
                        </button>
                        <span className="page-info">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button onClick={nextPage} disabled={currentPage === totalPages} className="pagination-button">
                            Next
                        </button>
                    </div>
                </>
            ) : (
                <p className="no-candidates-message">No candidates found.</p>
            )}
        </div>
    );
}

export default CandidatesInfoPage;
